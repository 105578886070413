declare var Swiper : any;

function activeDropDrown (){
    const subpages = document.getElementById('feed-subpages')
    if(subpages){

        const itemsDropDown = subpages.querySelectorAll('.feed-subpages .feed-subpages__option--sort-items')
        
        itemsDropDown.forEach(function(item){
            const nameDropDown = item.querySelector('.feed-subpages__option--sort-items-name')
            const optionsDropDown = item.querySelector('.feed-subpages__option--sort-items-options')
            const iconChange = item.querySelector('.icons__dropdrown')
            nameDropDown.addEventListener('click',()=>{
                nameDropDown.classList.toggle('active')
                optionsDropDown.classList.toggle('active')
                iconChange.classList.toggle('active')
            })
        })
    }
}



export const subPages = function(){
    activeDropDrown();
}