function clickOptions(){
    if(document.getElementById('careers-job-2')){
        var btn = document.querySelector('.careers-job-2 .careers-job-2__search  .careers-job-2__link ');
        const more =btn.querySelector('.more')
        const fewer =btn.querySelector('.fewer')
        var content = document.querySelector('.careers-job-2 .careers-job-2__search  .click');
        btn.addEventListener('click', function(){
            content.classList.toggle('active')
            more.classList.toggle('active')
            fewer.classList.toggle('active')
        })
    }
   
}
function handleOptionsJob() {
    if (document.getElementById('careers-job-2')) {
        var keyword = document.querySelector('.careers-job-2  .careers-job-Allposition .dropdown .dropdown__btn .desc');
        var location = document.querySelector('.careers-job-2  .careers-job-location .dropdown .dropdown__btn .desc');
        var level = document.querySelector('.careers-job-2  .careers-job-level .dropdown .dropdown__btn .desc');
        var type = document.querySelector('.careers-job-2  .careers-job-type .dropdown .dropdown__btn .desc');
        var btn = document.querySelector('.careers-job-2 .careers-job-2__btn');
        btn.addEventListener('click', function () {
            var dataBoxKeyword = keyword.getAttribute('data-box')
            var dataBoxLocation = location.getAttribute('data-box')
            var dataBoxLevel = level.getAttribute('data-box')
            var dataBoxType = type.getAttribute('data-box')
            submitSearch(dataBoxKeyword, dataBoxLocation, dataBoxLevel, dataBoxType);
        })
    }

    function submitSearch(keyword: string, location: string, level: string, type: string) {
        var infor = {
            KeyworDataBox: keyword,
            LocationDataBox: location,
            LevelDataBox: level,
            TypeDataBox: type,

        }
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState == 4 && this.status == 200) {

            }
        };
        xhttp.open("POST", "/infor-form", true);
        // đổi ojbectt thành chuỗi
        xhttp.send(JSON.stringify(infor));
        // khi gọi thành công 
        // inner.innerHTML =this.responseText;
        // product1();


        // khi thất bại
        console.log('thất bại');
    }
}
export const careersJob = function () {
    handleOptionsJob();
    clickOptions()
}