declare var Swiper : any;

function swiperBanner(){
  if (document.getElementById('banner')){
    var swiper = new Swiper("#banner .banner-swiper", {
      // direction: "vertical",
      loop: true,
      autoplay: {
        delay: 1500
      },

      pagination: {
        el: "#banner .banner-swiper .swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
    // new Swiper('#banner .banner-swiper', {
    //   loop: true,

    //   // autoplay: {
    //   //   delay: 3000,
    //   //   disableOnInteraction: false,
    //   // },

    //   pagination: {
    //     el: ".banner .swiper-pagination",
    //     clickable: true,
    //   },
    // })
  }
}

function subbannerPosition(){
  var banners = document.querySelectorAll('.banner');
  var header = document.querySelector('header');
  var hHeader = header.offsetHeight;
  var sectionBanner = document.getElementById('bannerSub');

  banners.forEach(function(item){
    if (item.classList.contains('bannersub')){
      sectionBanner.style.paddingTop = `${hHeader}px`;
    }
  })

  var spaces = document.querySelectorAll('.space-header');
  spaces.forEach(function(item){
    var itemE = <HTMLElement> item;
    itemE.style.paddingTop = `${hHeader}px`;
  })

  var banner = document.getElementById('banner');
  if (banner){
    console.log(hHeader);
    banner.style.paddingTop = `${hHeader}px`;
  }

  window.addEventListener('resize', function(e){
    // resize sub banner
    setTimeout(function(){
      var hHeaderResize = header.offsetHeight;
  
      banners.forEach(function(item){
        if (item.classList.contains('bannersub')){
          sectionBanner.style.paddingTop = `${hHeaderResize}px`;
        }
      })
      // resize space header
      spaces.forEach(function(item){
        var itemE = <HTMLElement> item;
        itemE.style.paddingTop = `${hHeaderResize}px`;
      })
      // resize banner
      if (banner){
        banner.style.paddingTop = `${hHeaderResize}px`;
      }
    }, 400);
  })
}
export const banner = function(){
  swiperBanner();
  subbannerPosition();
}