declare var Swiper : any;

function submitPopup(){
    const contactPage = document.getElementById('contact-us__wrapper')
    if(contactPage){
        const popupAlert = contactPage.querySelector('.popup-alert')
        const closePopupAlert = contactPage.querySelector('.popup-alert .popup-alert__wrapper--section-close')
        const submitBtn = contactPage.querySelector('#btnSubmitContact')
        const formPopup = contactPage.querySelector('.popup-alert .popup-alert__wrapper')
        if(submitBtn){

            submitBtn.addEventListener('click',(e)=>{
                e.preventDefault();
                console.log(popupAlert)
                popupAlert.classList.add("active")
            })
        }
        if(closePopupAlert){
            closePopupAlert.addEventListener('click',(e)=>{
                popupAlert.classList.remove("active")
            })
        }
        if(formPopup){
            formPopup.addEventListener('click',(e)=>{
                popupAlert.classList.remove("active")
            })
        }
    }
}



export const contactPage = function(){
    // submitPopup();
}