declare var Swiper : any;
declare var $ : any;
function changeSort(){
    const newsPage = document.getElementById('newsPage')
    if(newsPage){
        const btnSort = newsPage.querySelectorAll('.jf-details-header-news__decs-sortKind .link__tag')
        
        if(btnSort){
            btnSort.forEach(element => element.addEventListener('click',(e)=>{
                element.classList.add('active')
                const btnSortActive = newsPage.querySelectorAll('.jf-details-header-news__decs-sortKind .link__tag.active')
                if(btnSortActive){
                    
                    // btnSortActive.forEach(element =>element.addEventListener('click',()=>{
                        
                    // }))
                    btnSortActive.forEach(function(e){
                        const btnClearActive = e.querySelector('.link__tag--sort svg')
                        btnClearActive.addEventListener('click',()=>{
                            console.log(e)
                            e.classList.remove('active')
                        })
                    })  
                }
            }))
           
        }
        
        // if(submitBtn){

        //     submitBtn.addEventListener('click',(e)=>{
        //         e.preventDefault();
        //         console.log(popupAlert)
        //         popupAlert.classList.add("active")
        //     })
        // }
        // if(closePopupAlert){
        //     closePopupAlert.addEventListener('click',(e)=>{
        //         popupAlert.classList.remove("active")
        //     })
        // }
        // if(formPopup){
        //     formPopup.addEventListener('click',(e)=>{
        //         popupAlert.classList.remove("active")
        //     })
        // }
    }
}

function PopupVideo (){
    const newsdetailsvideoPage = document.getElementById('newsdetailsvideoPage')
    if(newsdetailsvideoPage){
        const videoClick = newsdetailsvideoPage.querySelector('.jf-details-header .jf-details-header-news__img-section .iframe-overlay')
        
        videoClick.addEventListener('click',()=>{
            const video = newsdetailsvideoPage.querySelector('.jf-details-header .jf-details-header-news__img-section iframe')
            const scrVideo = video.getAttribute('src') + "?rel=0&amp;autoplay=1"
            const popupVideo = newsdetailsvideoPage.querySelector('#popupVideo')
            const popupVideoChangeSrc = newsdetailsvideoPage.querySelector('#popupVideo iframe')
            popupVideoChangeSrc.setAttribute('src',scrVideo )
            popupVideo.classList.add('active')
            document.body.style.overflowY = "hidden";
            popupVideo.addEventListener('click',()=>{
                popupVideo.classList.remove('active')
                popupVideoChangeSrc.setAttribute('src',"" )
                document.body.style.overflowY = "scroll";
            })
        })
    }
}


export const newsPage = function(){
    // changeSort();
    PopupVideo();
}