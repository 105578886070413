function handleFormJob(){
    if(document.getElementById('careers-subpages-2')){
        var fullName = <HTMLInputElement>document.getElementById('inputName');
        var email = <HTMLInputElement>document.getElementById('inputEmail');
        var phone = <HTMLInputElement>document.getElementById('inputPhone');
        var adress = <HTMLInputElement>document.getElementById('inputAddress');
        var Cv = <HTMLInputElement>document.getElementById('inputChooseCv');
        var btnSubmit = <HTMLInputElement>document.getElementById('inputSubmit');
        btnSubmit.addEventListener('click',function(e){
            e.preventDefault();
            var valName = fullName.value;
            var valEmail = email.value;
            var valPhone = phone.value;
            var valAdress = adress.value;
            var valCv = Cv.value;
           
            submitForm(valName, valEmail, valPhone ,valAdress, valCv );
        })
    }
   
   
}
function submitForm(name: string, email: string, phone: string,address: string, Cv: string ){
    var infor = {
        Fullname: name,
        Email: email,
        Phone: phone,
        Address: address,
        Cv: Cv,
    }
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function() {
        if (this.readyState == 4 && this.status == 200) {
            
       }
    };
    xhttp.open("POST", "/infor-form", true);
    // đổi ojbectt thành chuỗi
    xhttp.send(JSON.stringify(infor));
    // khi gọi thành công 
    // inner.innerHTML =this.responseText;
    // product1();
    
    // khi thất bại
    console.log('thất bại');
}
function ChooseCv(){
    if(document.getElementById('careers-subpages-2')){
        var btnChooseCv = document.getElementById('clickCv');

        var Cv = document.getElementById('inputChooseCv');
        btnChooseCv.addEventListener('click', function(){
            Cv.click();
           
        })
    }
    
}
export const careersSubpages = function(){
  ChooseCv();
  handleFormJob();
  }