declare var Swiper : any;

function submitPopup(){
    const networkPage = document.getElementById('aboutNetworkPage');
    
    if(networkPage){
        const btnOpenSort = networkPage.querySelector('.map-input .map-input__icon-default')
        const btnCloseort = networkPage.querySelector('.map-input .map-input__icon-close')
        const cate = networkPage.querySelector('.map-recommend .map-recommend-cate');

        if (!btnCloseort || !btnOpenSort || !cate){
            console.log('error function submitPopup in aboutNetworkPage');
            return;
        }

        btnOpenSort.addEventListener('click',()=>{
            btnOpenSort.classList.add('hidden');
            btnCloseort.classList.remove('hidden');
            cate.classList.add('show');
        })
        btnCloseort.addEventListener('click',()=>{
            btnCloseort.classList.add('hidden');
            btnOpenSort.classList.remove('hidden');
            cate.classList.remove('show');
        })


        // if(submitBtn){

        //     submitBtn.addEventListener('click',(e)=>{
        //         e.preventDefault();
        //         console.log(popupAlert)
        //         popupAlert.classList.add("active")
        //     })
        // }
        // if(closePopupAlert){
        //     closePopupAlert.addEventListener('click',(e)=>{
        //         popupAlert.classList.remove("active")
        //     })
        // }
        // if(formPopup){
        //     formPopup.addEventListener('click',(e)=>{
        //         popupAlert.classList.remove("active")
        //     })
        // }
    }
}



export const aboutNetwork = function(){
    submitPopup();
}