declare var Swiper: any;

function swiperThumbs() {
  if (document.querySelector('.farm-subchildren-1')) {
    var swiper = new Swiper(".farm-subchildren-1  .farm-subchildren-1-thumbs .swiper-container", {
      // direction: "vertical",
      spaceBetween: 12,
      slidesPerView: 3,
      speed: 700,
      // lazy: true,
      loop: false,
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,

      navigation: {

      },


      breakpoints: {
        // 0: {
        //   slidesPerView: 1,
        // },
        // 320: {
        //   slidesPerView: 1.25,
        // },
        // 540: {
        //   slidesPerView: 2,
        // },
        // 768: {
        //   slidesPerView: 3,
        // }
      }

      // on: {
      //   init: function () {
      //     var activeSlide = document.querySelector('.restroom-popup--overlay.show .popup-wrapper__imgs-thumbs .swiper-slide-active')
      //     // if (!activeSlide){
      //     //   activeSlide = document.querySelector('.restroom-popup.show .popup-wrapper__imgs-thumbs .swiper-slide-active')
      //     // }
      //     activeSlide.classList.add('active')
      //   },
      //   transitionStart: function() {
      //     var activeSlides = document.querySelectorAll('.restroom-popup--overlay.show .popup-wrapper__imgs-thumbs .swiper-slide')
      //     activeSlides.forEach(function(item){
      //       item.classList.remove('active')
      //     })
      //   },
      //   transitionEnd: function() {
      //     var activeSlide = document.querySelector('.restroom-popup--overlay.show .popup-wrapper__imgs-thumbs .swiper-slide-active')
      //     // if (!activeSlide){
      //     //   activeSlide = document.querySelector('.restroom-popup.show .popup-wrapper__imgs-thumbs .swiper-slide-active')
      //     // }
      //     activeSlide.classList.add('active')
      //   }
      // }
    })

    var swiper2 = new Swiper(".farm-subchildren-1  .farm-subchildren-1-top .swiper-container", {
      spaceBetween: 12,
      slidesPerView: 1,

      navigation: {
      },

      // zoom: true,
      // lazy: true,

      thumbs: {
        swiper: swiper,
      },

      on: {
        init: function () {
          var activeSlide = document.querySelector('.farm-subchildren-1 .farm-subchildren-1-thumbs  .swiper-slide-thumb-active')
          activeSlide.classList.add('active')
        },
        transitionStart: function () {
          var activeSlides = document.querySelectorAll('.farm-subchildren-1 .farm-subchildren-1-thumbs  .swiper-slide')
          activeSlides.forEach(function (item) {
            item.classList.remove('active')
          })

        },
        transitionEnd: function () {
          var activeSlide = document.querySelector('.farm-subchildren-1 .farm-subchildren-1-thumbs  .swiper-slide-thumb-active')
          activeSlide.classList.add('active')
        }
      }
    });
    zoomImg('.farm-subchildren-1 .farm-subchildren-1-top .swiper-slide');
  }
}

function zoomImg(qStr: string) {
  var listItem = document.querySelectorAll(qStr);
  listItem.forEach(function (item) {
    item.addEventListener('mousemove', function (e: MouseEvent) {
      var imgItem = item.getElementsByTagName('img')[0];

      var pex = e.offsetX;
      var pey = e.offsetY;
      
      imgItem.style.transformOrigin = `${pex}px ${pey}px`;
      imgItem.classList.add('scale');
      imgItem.style.cursor = "zoom-out";
    })
    item.addEventListener('mouseleave', function (e: MouseEvent) {
      var imgItem = item.getElementsByTagName('img')[0];

      var pex = e.offsetX;
      var pey = e.offsetY;

      imgItem.style.transformOrigin = `${pex}px ${pey}px`;
      imgItem.classList.remove('scale');
      imgItem.style.cursor = "zoom-out";

    })
  })
}
export const farmSubchildren = function () {
  swiperThumbs();
}